<template>
    <section class="my-0" :class="{'section': desktop}">
        <card class="border-0">
            <div class="row justify-content-md-center">
                <div class="col-lg-6">
                    <card type="secondary" shadow
                          header-classes="bg-white pb-5"
                          body-classes="px-lg-5 py-lg-5"
                          class="border-0">
                        <template>
                            <base-alert shadow type="primary">
                                <span class="lead text-white">{{$t('feedback.name')}}</span>
                            </base-alert>
                            <base-alert v-if="alert" :type="alert_type">
                                <span class="alert-inner--text">{{alert_message}}</span>
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="alert = false">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </base-alert>
                            <form class="mt-5" role="form">
                                <star-rating class="justify-content-center"
                                             v-model="model.rating"
                                             :star-size="35"
                                             :show-rating="false"></star-rating>
                                <base-input class="mt-4" :label="$t('feedback.label.subject')" alternative v-model="model.subject" atocomplete="false" />

                                <base-input class="mt-4 input-group-alternative" :label="$t('feedback.label.message')">                                    
                                    <textarea class="form-control form-control-alternative" rows="3" v-model="model.message"></textarea>
                                </base-input>

                                <base-button class="mt-4" block type="primary" @click="submitModel()">{{$t('feedback.label.submit')}}</base-button>
                            </form>
                            <!-- Add field for the user to see feedbacks he already sent. -->
                        </template>
                    </card>
                </div>
            </div>
        </card>
    </section>
</template>

<script>
    import StarRating from 'vue-star-rating'
    import $ from 'jquery';

    export default {
        name: 'Feedback',
        components: {
            StarRating
        },
        data() {
            return {
                model: {
                    rating: 0,
                    subject: '',
                    message: '',
                },

                alert_message: '',
                alert_type: 'warning',
                alert: false,
                desktop: screen.width > 700,
            }
        },

        methods: {
            submitModel() {
                var gtag = this.$gtag;

                if (this.model.message == '' && this.model.subject == '' && this.model.rating == 0) {
                    alert(this.$data, 'warning', this.$t('warning.feedback.warning'));
                } else {
                    $.ajax({
                        url: 'https://api.immobl.com/feedback',
                        contentType: 'application/json;charset=UTF-8',
                        xhrFields: {
                            withCredentials: true
                        },
                        crossDomain: true,
                        type: 'POST',
                        data: JSON.stringify({ data: this.model }),
                        success: function (response) {
                            if (response.success) {
                                gtag.event('send_feedback');
                            }
                        },
                        //error: function (err) {
                        //    //console.log(err);
                        //}
                    });

                    alert(this.$data, 'success', this.$t('warning.feedback.success'));
                    this.model = {
                        rating: 0,
                        subject: '',
                        message: '',
                    }
                }

                function alert(data, type, message) {
                    data.alert = true;
                    data.alert_type = type;
                    data.alert_message = message;
                    $("html, body").animate({ scrollTop: 0 }, 1000);
                }

            },
        },

        mounted() {
            this.$gtag.set({ 'user_id': this.$store.state._id });
        }
    }
</script>

<style scoped>
    .form {
        width: 50%;
        margin-left: 6px;
    }

    .alert-border {
        border-color: red;
    }
</style>